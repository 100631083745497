
export default
	props: block: Object

	inject: blockIndex: default: 0

	computed:
		imageDesktop: -> if @block.imageDesktop.length then @block.imageDesktop else @block.imageMobile
		imageMobile: -> if @block.imageMobile.length then @block.imageMobile else @block.imageDesktop

		videoDesktop: -> if @block?.videoDesktop?.length then @block.videoDesktop else @block.videoMobile
		videoMobile: -> if @block?.videoMobile?.length then @block.videoMobile else @block.videoDesktop

		classes: -> ['marquee' if @blockIndex == 0]

	methods:
		play: ->
			if @block.playButtonVideo?.length
				@$store.commit(
					'videoModal/play'
					{
						video: @block?.playButtonVideo?[0]?.videoDesktop?[0] || @block?.playButtonVideo?[0]?.videoMobile?[0],
						title: @block?.playButtonVideo?[0]?.title,
						subtitles: @block?.playButtonVideo?[0]?.subtitles, libraryVideoId: @block?.playButtonVideo?[0]?.id
					}
				)


